.announcement-container {
  display: flex;
  flex-direction: row;
}

.announcement {
  display: flex;
  flex-direction: row;
  margin-left: 20%;

  .pass-edit {
    margin-top: 0;
  }

  .title {
    display: inline-flex;
    height: 4.375rem;
    padding: 0.75rem 0.75rem 0.75rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    flex-shrink: 0;
    background: var(--white, #fff);
    position: relative;
    top: 7rem;
    right: 1rem;

    h1 {
      font-size: 2rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 2.8rem */
      background: var(
        --gradient-golden-button,
        linear-gradient(
          98deg,
          #a27b1f -11.22%,
          #d2aa46 10.36%,
          #f4d980 31.94%,
          #e3ba6a 53.52%,
          #f4d067 75.1%,
          #c3a364 96.68%,
          #956e0f 123.65%
        )
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .ann-btn {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
  }

  .del-btn {
    cursor: pointer;
    display: flex;
    padding: 0.8125rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    flex: 1 0 0;
    align-self: stretch;

    border-radius: 1rem;
    border: 2px solid var(--red, #f63535);
    background-color: #fff;

    color: var(--red, #f63535);
    text-align: center;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 1.75rem */
  }

  .edit-btn {
    cursor: pointer;
    display: flex;
    padding: 0.8125rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    flex: 1 0 0;
    border: none;
    border-radius: 1rem;
    background: var(
      --gradient-gold-slanted,
      linear-gradient(
        126deg,
        #a27b1f 1.44%,
        #d2aa46 17.18%,
        #fae68c 32.92%,
        #e3ba6a 48.66%,
        #fdda75 64.4%,
        #c3a364 80.14%,
        #956e0f 99.82%
      )
    );

    /* button-default */
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25);

    color: var(--on-gold, #5a3c1a);
    text-align: center;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 1.75rem */
  }

  textarea {
    display: flex;
    padding: 1rem 0.75rem 1rem 1rem;
    align-items: flex-start;
    gap: 0.625rem;
    align-self: stretch;
    background-color: #fff;
    width: 95%;

    border-radius: 1.25rem;
    border: 1px solid var(--on-gold, #5a3c1a);

    color: var(--black, #252a2a);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 1.575rem */
  }
}

.rmdp-arrow {
  border: solid #e9d2a8 !important;
  border-width: 0 2px 2px 0 !important;
}

.rmdp-arrow-container:hover {
  background-color: #5a3c1a !important;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
  background: var(
    --gradient-gold-slanted,
    linear-gradient(
      126deg,
      #a27b1f 1.44%,
      #d2aa46 17.18%,
      #fae68c 32.92%,
      #e3ba6a 48.66%,
      #fdda75 64.4%,
      #c3a364 80.14%,
      #956e0f 99.82%
    )
  );
  color: #000 !important;
}

.rmdp-day.rmdp-today span,
.rmdp-day span:hover {
  background-color: #e9d2a8 !important;
  color: #000 !important;
}
.rmdp-week-day {
  color: #5a3c1a !important;
}

.rmdp-container,
.edit-items .pass-item input {
  width: 98%;
}

.calendar {
  position: relative;
  bottom: 2.7rem;
  left: 36rem;
}

@media (max-width: 1500px) {
  .announcement {
    width: 100%;
    margin-left: 25%;
    .info {
      width: 54%;
    }
    .gr-br-5 {
      width: 102%;
    }
    .bg-white {
      left: 2rem;
    }
    .calendar {
      left: 95%;
    }
  }
}
@media (max-width: 1300px) {
  .announcement {
    .info {
      width: 35%;
    }
    .gr-br-5 {
      width: 80%;
    }
    .bg-white {
      left: -1rem;
    }
  }
}

@media (max-width: 1000px) {
  .announcement {
    flex-direction: column;
    .info {
      height: min-content;
      width: 90%;
      margin-bottom: 0;
    }
    .gr-br-5 {
      width: 100%;
    }
    .pass-edit {
      margin-top: 0;
      margin-left: 1rem;
    }
    .bg-white {
      left: 4rem;
    }
    .calendar {
      left: 95%;
    }
  }
}

@media (max-width: 500px) {
  .announcement-container {
    * {
      font-size: 0.8rem !important;
    }

    flex-direction: column;

    .info {
      margin-top: 5rem;
      width: 84%;
    }
    .ann-btn {
      flex-direction: column;
    }
    .edit-btn {
      width: 100%;
    }
    .pass-edit {
      margin: 1rem;
    }
    .bg-white {
      left: -4rem;
    }
    .calendar {
      left: 90%;
    }
  }
}
