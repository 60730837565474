.gr-br-2 {
  position: fixed;
  border-radius: 1rem;
  padding: 1px;
  background: linear-gradient(
    -60deg,
    #a27b1f 0%,
    #d2aa46 16%,
    #fae68c 32%,
    #e3ba6a 48%,
    #fdda75 64%,
    #c3a364 80%,
    #956e0f 100%
  );
  width: 20rem;
  height: 54.0625rem;
  margin: 2rem 2rem 3rem 2rem;
}

.navbar-container {
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex-shrink: 0;
  border-radius: 1rem;
  .navlink {
    text-decoration: none;
    display: flex;
    height: 4rem;
    padding: 0rem 1rem;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    align-self: stretch;

    color: var(--grey-dark, #424848);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: var(--Body-Large-Line-Height, 1.5rem); /* 120% */
    letter-spacing: var(--Body-Large-Tracking, 0.03125rem);
  }
}

.logo {
  display: flex;
  padding: 1.5rem 2rem 1.5rem 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
  align-self: stretch;
}

.nav-container {
  display: flex;
  padding-top: 1rem;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.exit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;

  .logout {
    text-decoration: none;
    color: var(--red, #f63535);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: var(--Body-Large-Line-Height, 1.5rem); /* 120% */
    letter-spacing: var(--Body-Large-Tracking, 0.03125rem);
  }
}

.mini-navbar-container,
.mini-exit {
  display: none;
}

.dropdown {
  position: relative;
  cursor: pointer;
}

.dropdown-menu {
  // position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border-radius: 0.5rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 10;
}

.dropdown-item {
  width: 100%;
  display: flex;
  height: 3rem;
  padding: 0rem 1rem 0rem 2.5rem;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  align-self: stretch;
  text-decoration: none;
  color: #000;

  &:hover {
    background-color: #f5f5f5;
  }
}

@media (max-width: 1500px) {
  .gr-br-2 {
    height: 50rem;
  }
}
@media (max-width: 1300px) {
  .gr-br-2 {
    width: 16rem;
    margin-right: 2rem;
    height: 46rem;
  }
}

@media (max-width: 1000px) {
  .gr-br-2 {
    margin-right: 1rem;
  }
}

@media (max-width: 500px) {
  .navbar-container {
    display: none;
  }

  .mini-exit {
    display: block;
    display: flex;
    width: 84%;
    justify-content: space-between;
    align-items: center;
    margin: 1.5rem 2rem;

    .exit {
      display: flex;
      padding: 0rem 0.5rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: stretch;
    }
  }

  .gr-br-2 {
    margin-top: 0;
    padding: 1px;
    width: 88%;
    height: auto;
  }

  .mini-navbar-container {
    border-radius: 1rem;
    background-color: #fff;
    display: flex;
    height: 4rem;
    justify-content: space-between;
    align-items: flex-start;
    flex-shrink: 0;
  }

  .mini-nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .navlink {
      display: flex;
      height: 4rem;
      padding: 0rem 1.25rem;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }
  }
}
