.advertisement-container {
  display: flex;
  // align-items: flex-start;
  // justify-content: center;

  h1 {
    margin-left: 20%;
    margin-top: 20%;
  }
}

@media (max-width: 1500px) {
  .advertisement-container {
    h1 {
      margin-left: 30%;
    }
  }
}
@media (max-width: 500px) {
  .advertisement-container {
    * {
      font-size: 0.8rem !important;
    }

    flex-direction: column;
  }
}
