.messages-container {
  display: flex;
  flex-direction: row;

  .messages {
    display: flex;
    flex-direction: row;
    margin-left: 20%;
    width: 80%;
  }

  .msg-table {
    margin: 7.5rem 2rem;
    width: 90%;

    .row-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    .number {
      display: flex;
      width: 1rem;
      height: 1rem;
      padding: 0.375rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.625rem;

      border-radius: 6.25rem;
      background: var(
        --gradient-gold-slanted,
        linear-gradient(
          126deg,
          #a27b1f 1.44%,
          #d2aa46 17.18%,
          #fae68c 32.92%,
          #e3ba6a 48.66%,
          #fdda75 64.4%,
          #c3a364 80.14%,
          #956e0f 99.82%
        )
      );
    }

    .titles,
    .rows {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 2fr;
      gap: 0.625rem;
      align-items: flex-start;
      text-align: start;
      padding: 0.375rem 2rem;

      &.titles {
        margin-bottom: 1.5rem;
        border-radius: 1rem;
        background: var(
          --gradient-gold-slanted,
          linear-gradient(
            126deg,
            #a27b1f 1.44%,
            #d2aa46 17.18%,
            #fae68c 32.92%,
            #e3ba6a 48.66%,
            #fdda75 64.4%,
            #c3a364 80.14%,
            #956e0f 99.82%
          )
        );
      }

      &.rows {
        border-radius: 1.25rem;
        border: 1px solid var(--devider-2, #e8d8bb);
        background: var(--white, #fff);
        padding: 1rem 2rem;
      }
    }

    .message-content {
      position: relative;
      cursor: pointer;
      max-width: 100%;
      line-height: 1.4;
      // max-width: 250px;

      // Text truncation
      overflow: hidden;
      // white-space: nowrap;
      text-overflow: ellipsis;

      // Tooltip styles for inline expansion
      .tooltip {
        display: inline; // Inline display to follow ellipsis directly
        font-weight: normal;
        color: inherit;
        white-space: normal;
      }
    }
  }
}

@media (max-width: 1500px) {
  .messages-container {
    .messages {
      margin-left: 25%;
    }
  }
}
@media (max-width: 500px) {
  .messages-container {
    * {
      font-size: 0.8rem !important;
    }

    flex-direction: column;
    justify-content: center;
    align-items: center;

    .messages,
    .msg-table {
      width: 100%;
    }
  }
}
