.profile-setting-container {
  display: flex;
  flex-direction: row;
}

.profile-setting {
  display: flex;
  flex-direction: row;
  margin-left: 20%;
}

.info {
  display: flex;
  width: 40.625rem;
  height: 48.8125rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.5rem;
  flex-shrink: 0;
  margin: 7.25rem 5rem 3rem 2rem;

  .item {
    width: 100%;
  }

  label {
    align-self: stretch;
    color: var(--on-gold, #5a3c1a);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 1.75rem */
  }

  input {
    width: 100%;
    display: flex;
    padding: 1rem 0.75rem 1rem 1rem;
    align-items: center;
    gap: 0.625rem;
    align-self: stretch;
    border-radius: 1.25rem;
    border: 1px solid var(--devider1, #e9d2a8);
    width: 95%;
    background-color: #fff;
  }
  input:focus {
    border: 2px solid var(--devider1, #e9d2a8);
  }

  .gr-br-4 {
    margin-left: 0.5rem;
    background: linear-gradient(
      90deg,
      #a27b1f 0%,
      #d2aa46 16%,
      #fae68c 32%,
      #e3ba6a 48%,
      #fdda75 64%,
      #c3a364 80%,
      #956e0f 100%
    );
    padding: 2px;
    border-radius: 0.75rem;
    box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.2),
      0px 0px 8px 1px rgba(0, 0, 0, 0.15);

    button {
      cursor: pointer;
      background: var(
        --gradient-gold-slanted,
        linear-gradient(
          126deg,
          #a27b1f 1.44%,
          #d2aa46 17.18%,
          #fae68c 32.92%,
          #e3ba6a 48.66%,
          #fdda75 64.4%,
          #c3a364 80.14%,
          #956e0f 99.82%
        )
      );
      border-radius: 0.75rem;
      display: flex;
      padding: 1rem;
      align-items: center;
      gap: 5.3125rem;
      border: none;
    }
  }
  .pass-in {
    display: flex;
    flex-direction: row;
  }
}

.gr-br-5 {
  background: var(
    --gradient-gold-slanted,
    linear-gradient(
      126deg,
      #a27b1f 1.44%,
      #d2aa46 17.18%,
      #fae68c 32.92%,
      #e3ba6a 48.66%,
      #fdda75 64.4%,
      #c3a364 80.14%,
      #956e0f 99.82%
    )
  );
  padding: 1px;
  border-radius: 1rem;
  width: 43.75rem;
}

.pass-edit {
  margin: 2.41rem 4.62rem 10.03rem 0rem;
}

.edit {
  padding: 3.5rem 2.5rem 2rem 2.5rem;
  background: var(--white, #fff);
  border-radius: 1rem;
  /* container */
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2),
    0px 0px 8px 1px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
  align-self: stretch;

  .reset-btn {
    button {
      cursor: pointer;
      display: flex;
      padding: 0.8125rem 3.81rem;
      justify-content: center;
      align-items: center;
      gap: 0.625rem;
      align-self: stretch;
      border-radius: 1rem;
      background: var(
        --gradient-gold-slanted,
        linear-gradient(
          126deg,
          #a27b1f 1.44%,
          #d2aa46 17.18%,
          #fae68c 32.92%,
          #e3ba6a 48.66%,
          #fdda75 64.4%,
          #c3a364 80.14%,
          #956e0f 99.82%
        )
      );

      /* button-default */
      box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25);
      color: var(--on-gold, #5a3c1a);
      text-align: center;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 1.75rem */
      border: none;
    }
  }
}

.edit-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  align-self: stretch;

  .text {
    align-self: stretch;
    color: var(--grey-dark, #424848);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 1.575rem */
  }

  .pass-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    label {
      align-self: stretch;
      color: var(--on-god, #5a3c1a);
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 1.75rem */
    }

    input {
      display: flex;
      padding: 1rem 0.75rem 1rem 1rem;
      align-items: center;
      gap: 0.625rem;
      align-self: stretch;

      border-radius: 1.25rem;
      border: 2px solid var(--devider1, #e9d2a8);
      background: var(--white, #fff);
    }
  }
}

.gr-br-6 {
  background: linear-gradient(
    90deg,
    #a27b1f 0%,
    #d2aa46 16%,
    #fae68c 32%,
    #e3ba6a 48%,
    #fdda75 64%,
    #c3a364 80%,
    #956e0f 100%
  );
  padding: 2px;
  border-radius: 0.75rem;
  width: 2.25rem;
  /* mode button- selected */
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 0px 8px 1px rgba(0, 0, 0, 0.15);
}

.bg-white {
  display: inline-flex;
  padding: 0.75rem 1.5rem 0.75rem 0.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  background: var(--white, #fff);
  margin-left: 27.56rem;
  position: relative;
  top: 3rem;
  left: 14rem;
}

.close {
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  padding: 0.5rem;
  align-items: center;
  gap: 5.3125rem;
  border-radius: 0.75rem;
  background: var(
    --gradient-gold-slanted,
    linear-gradient(
      126deg,
      #a27b1f 1.44%,
      #d2aa46 17.18%,
      #fae68c 32.92%,
      #e3ba6a 48.66%,
      #fdda75 64.4%,
      #c3a364 80.14%,
      #956e0f 99.82%
    )
  );
}

.profile-setting {
  .title {
    position: relative;
    display: inline;
    top: 5rem;
    right: 1rem;
    background: var(--white, #fff);
    padding: 0.75rem 0.75rem 0.75rem 1.5rem;
  }
  h1 {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    flex-shrink: 0;
    text-align: right;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 2.8rem */
    background: var(
      --gradient-golden-button,
      linear-gradient(
        98deg,
        #a27b1f -11.22%,
        #d2aa46 10.36%,
        #f4d980 31.94%,
        #e3ba6a 53.52%,
        #f4d067 75.1%,
        #c3a364 96.68%,
        #956e0f 123.65%
      )
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

@media (max-width: 1500px) {
  .profile-setting {
    width: 100%;
    margin-left: 25%;
    .info {
      width: 45%;
    }
    .gr-br-5 {
      width: 105%;
    }

    .bg-white {
      left: 5rem;
    }
  }
}

@media (max-width: 1300px) {
  .profile-setting {
    .info {
      width: 30%;
    }
    .gr-br-5 {
      width: 100%;
    }

    .bg-white {
      left: 2rem;
    }
  }
}
@media (max-width: 1000px) {
  .profile-setting {
    flex-direction: column;
    .info {
      height: min-content;
      width: 80%;
      margin-bottom: 0;
    }
    .gr-br-5 {
      width: 100%;
    }
    .pass-edit {
      margin-top: 0;
      margin-left: 1rem;
    }
    .bg-white {
      left: 4rem;
    }
  }
}

@media (max-width: 500px) {
  .profile-setting-container {
    * {
      font-size: 0.8rem !important;
    }
    flex-direction: column;

    .info {
      margin-top: 5rem;
    }
    .pass-edit {
      margin: 1rem;
    }
    .bg-white {
      left: -4rem;
    }
    .calendar {
      left: 90%;
      }
  }
}
