* {
  padding: 0;
  margin: 0;
  font-family: "Frank Ruhl Libre", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background-color: rgb(243 244 246);
  background-color: #fff;
  -ms-overflow-style: none;
  width: 100%;
  /* scrollbar-width: none; */
}

/* Chrome */
body::-webkit-scrollbar {
  display: none !important;
}
