.exchange-rate-container {
  display: flex;
  flex-direction: row;

  .gr-br-3 {
    border-radius: 1rem;
    padding: 1px;
    background: linear-gradient(
      -60deg,
      #a27b1f 0%,
      #d2aa46 16%,
      #fae68c 32%,
      #e3ba6a 48%,
      #fdda75 64%,
      #c3a364 80%,
      #956e0f 100%
    );
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2),
      0px 0px 8px 1px rgba(0, 0, 0, 0.15);
    width: 61.25rem;
  }

  .rate-table {
    display: flex;
    padding: 0rem 1.5rem 2rem 1.5rem;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    background: var(--white, #fff);
    border-radius: 1rem;

    .header {
      display: flex;
      width: 88.8%;
      padding: 1.47rem 5.575rem 1.22rem 4rem;
      justify-content: space-between;
      align-items: center;
      border-radius: 1rem 1rem 0rem 0rem;
      border-bottom: 0.5px solid var(--devider1, #e9d2a8);
      background: var(
        --gradient-golden-button,
        linear-gradient(
          98deg,
          #a27b1f -11.22%,
          #d2aa46 10.36%,
          #f4d980 31.94%,
          #e3ba6a 53.52%,
          #f4d067 75.1%,
          #c3a364 96.68%,
          #956e0f 123.65%
        )
      );
    }
    .inner-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 94%;
      * {
        color: var(--on-god, #5a3c1a);
        text-align: center;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 1.575rem */
      }
      .white {
        * {
          color: #fff;
        }
      }
    }

    .prices {
      margin-right: 1rem;
      width: 100%;
      overflow-y: scroll;
      height: 22rem;
      margin-bottom: 3rem;
      padding: 0 1.5rem 2rem 1.5rem;
      .item {
        display: flex;
        padding: 0.75rem 2.5rem;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-bottom: 0.5px solid var(--devider-2, #e8d8bb);
      }
      .g-2 {
        display: flex;
        justify-content: space-between;
        width: 62%;

        input {
          color: var(--black, #252b2b);
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 1.575rem */
        }
      }

      .title-1 {
        color: var(--black, #252b2b);
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 1.575rem */
      }

      .title-2 {
        color: var(--grey, #858888);
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 1.225rem */
      }
    }

    .prices::-webkit-scrollbar,
    .prices .list-2::-webkit-scrollbar {
      width: 4px;
      height: 1rem;
      border-bottom-right-radius: 16px;
      margin-right: 1rem;
    }

    .prices::-webkit-scrollbar-track,
    .prices .list-2::-webkit-scrollbar-track {
      background: #fff;
      border-bottom-right-radius: 16px;
      margin-right: 1rem;
    }

    .prices::-webkit-scrollbar-thumb,
    .prices .list-2::-webkit-scrollbar-thumb {
      background: linear-gradient(
        90deg,
        #a27b1f 0%,
        #d2aa46 16%,
        #f4d980 32%,
        #e3ba6a 48%,
        #f4d067 64%,
        #c3a364 80%,
        #956e0f 100%
      );
      border-bottom-right-radius: 16px;
      border-radius: 50px;
      margin-right: 1rem;
      // border: 3px solid orange;
    }
    .rate-in {
      display: flex;
      width: 9.375rem;
      padding: 0.75rem 0.75rem 0.75rem 1rem;
      align-items: center;
      gap: 0.625rem;
      border-radius: 1rem;
      border: 2px solid var(--devider1, #e9d2a8);
      background: var(--white, #fff);
    }
  }

  .update-btn {
    button {
      cursor: no-drop;
      border: none;
      display: flex;
      width: 13.75rem;
      height: 2.875rem;
      padding: 0.625rem 1.5rem;
      justify-content: center;
      align-items: center;
      gap: 0.625rem;
      margin-top: 2.5rem;

      border-radius: 1rem;
      background: var(--gold-disabled, #c5ad81);

      color: var(--devider-2, #e8d8bb);
      text-align: center;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 1.575rem */
    }

    .btn-edited {
      cursor: pointer;
      display: flex;
      padding: 0.625rem 1.5rem;
      justify-content: center;
      align-items: center;
      gap: 0.625rem;
      flex: 1 0 0;

      border-radius: 1rem;
      background: var(
        --gradient-gold-slanted,
        linear-gradient(
          126deg,
          #a27b1f 1.44%,
          #d2aa46 17.18%,
          #fae68c 32.92%,
          #e3ba6a 48.66%,
          #fdda75 64.4%,
          #c3a364 80.14%,
          #956e0f 99.82%
        )
      );

      /* button-default */
      box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25);

      color: var(--on-god, #5a3c1a);
      text-align: center;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 1.575rem */
    }
  }

  .rate-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5rem auto;
    // margin-left: 16.38rem;
  }

  .in-g-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      margin: 0 5px;
    }
  }

  .title-rate {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mode-selection {
    display: flex;
    padding: 1.5rem 2rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;

    border-top: 1px solid var(--devider-2, #e8d8bb);
    border-bottom: 1px solid var(--devider-2, #e8d8bb);
    background: var(--white, #fff);
  }

  .mode-title {
    display: flex;
    align-items: center;
    gap: 0.375rem;
  }

  .radio-options {
    display: flex;
    align-items: flex-start;
    gap: 5rem;
  }
}

@media (max-width: 1500px) {
  .rate-container {
    margin-left: 2%;
  }
}
@media (max-width: 1300px) {
  .rate-container {
    margin-left: 0;
    .gr-br-3 {
      width: 50rem;
    }
    .rate-table .header {
      width: 86%;
    }
  }
}
@media (max-width: 1000px) {
  .rate-container {
    .gr-br-3 {
      width: 90%;
    }
    .rate-table .header {
      width: 88%;
      padding: 1.47rem 2rem 1.22rem 2rem;
    }
    .rate-in {
      width: 50%;
    }
    .prices .item {
      padding: 0.75rem 1rem;
    }
    .g-2 {
      gap: 1rem;
    }
  }
}

@media (max-width: 500px) {
  .exchange-rate-container {
    * {
      font-size: 0.8rem !important;
    }
    flex-direction: column;
  }
  .rate-container {
    margin-top: 5.5rem;
    margin-bottom: 10rem;
    .white {
      display: flex;
      flex-direction: column;
    }
    .rate-in {
      width: 90%;
    }
    .g-3 {
      width: max-content;
    }
    .prices .g-2 {
      width: 70%;
    }
    .prices .item {
      padding: 0.5rem;
    }
    .rate-table {
      padding: 0rem 0.8rem 2rem 0.8rem;
    }
    .rate-table .prices {
      margin-right: 0rem;
      padding: 0.5rem;
      margin-bottom: 2rem;
    }
  }
}
