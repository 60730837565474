.login-page {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  color: var(--on-god, #5a3c1a);
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 1.75rem */
}

.gr-br-1 {
  width: 43.75rem;
  border-radius: 1rem;
  padding: 1px;
  background: linear-gradient(
    -60deg,
    #a27b1f 0%,
    #d2aa46 16%,
    #fae68c 32%,
    #e3ba6a 48%,
    #fdda75 64%,
    #c3a364 80%,
    #956e0f 100%
  );
  /* container */
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2),
    0px 0px 8px 1px rgba(0, 0, 0, 0.15);
}

.login-container {
  background-color: #fff;
  display: flex;
  padding: 3.5rem 2.5rem 2rem 2.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.5rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
  align-self: stretch;

  .input-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    align-self: stretch;
    width: 100%;
  }
  .input {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  input {
    display: flex;
    padding: 1rem 0.75rem 1rem 1rem;
    align-items: center;
    gap: 0.625rem;
    align-self: stretch;
    border-radius: 1.25rem;
    border: 1px solid var(--devider1, #e9d2a8);
    background: var(--white, #fff);
  }
  input:focus {
    border: 2px solid var(--devider1, #e9d2a8) !important;
  }
  button {
    border: none;
    display: flex;
    padding: 0.8125rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    align-self: stretch;
    border-radius: 1rem;
    background: var(
      --gradient-gold-slanted,
      linear-gradient(
        126deg,
        #a27b1f 1.44%,
        #d2aa46 17.18%,
        #fae68c 32.92%,
        #e3ba6a 48.66%,
        #fdda75 64.4%,
        #c3a364 80.14%,
        #956e0f 99.82%
      )
    );
    /* button-default */
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25);
  }

  .login-btn {
    display: flex;
    width: 16.25rem;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    button,
    .forgot-pass {
      cursor: pointer;
    }
  }
}

@media (max-width: 1500px) {
  .login-page {
    * {
      font-size: 0.8rem !important;
    }
    .gr-br-1 {
      margin: 1rem;
    }
  }
}
